.root {
  display: inline-block;
  text-align: center;

  &.rootMedium {
    min-width: 35px;
  }

  &.rootLarge {
    min-width: 50px;
  }
}

.interactive {
  cursor: pointer;
}
