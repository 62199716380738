.avatar {
  &.avatarSmall {
    --avatar-size: 1.8rem;
    font-size: 1.2rem;
  }

  &.avatarMedium {
    --avatar-size: 2.8rem;
    font-size: 1.4rem;
  }

  &.avatarLarge {
    --avatar-size: 4rem;
    font-size: 1.6rem;
  }

  color: var(--color-white);
  width: var(--avatar-size);
  height: var(--avatar-size);
  display: flex;
  position: relative;
  overflow: hidden;
  align-items: center;
  flex-shrink: 0;
  user-select: none;
  justify-content: center;

  svg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    fill: var(--color-purple);
  }

  &:first-of-type {
    mask {
      display: none;
    }
  }
}

.avatarFirstLetter {
  position: relative;
  font-weight: 500;
}

.avatarContainer {
  display: flex;
  align-items: center;
}

.avatarImageContainer {
  margin-right: var(--base-unit-m);
}

.avatarName {
  white-space: nowrap;
  max-width: 21.5rem;
  line-height: 1;

  .companyName {
    text-overflow: ellipsis;
    overflow: hidden;
    font-size: 1.4rem;
  }

  .userName {
    font-size: 1.2rem;
  }
}

.avatarOnlineBadge {
  background: var(--color-accent);
  height: 8px;
  width: 8px;
  border-radius: 50%;
  position: absolute;
  bottom: 1px;
  right: 1px;
  box-shadow: 0 0 2px 0 var(--color-neutral-not-so-light);
}
