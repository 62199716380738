.root {
  display: flex;

  &.small {
    --offset: -4px;
  }

  &.medium {
    --offset: -6px;
  }

  &.large {
    --offset: -8px;
  }

  > :not(:first-child) {
    margin-left: var(--offset);
  }
}
