@import "styles/mixins/_mixins.less";

.media-preview {
  height: 10.0rem;
  width: 10.0rem;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  flex: none;

  @media (pointer: fine) {
    &:hover {
      .media-preview__label {
        display: block;
      }
    }
  }

  img {
    max-height: 100%;
    max-width: 100%;
    object-fit: contain;
  }

  &--active {
    position: relative;

    &:before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      border: thin solid @color-white;

      .media-breakpoint-down(@screen-sm, {
        border-width: thin;
      });
    }
  }

  &--xxs {
    .xxs-container;
  }

  &--xs {
    .xs-container;
  }

  &--s {
    .s-container;
  }

  &--m {
    .m-container;
  }

  &--l {
    .l-container;
  }

  &--disabled {
    opacity: 0.3;
  }

  &__video-flag {
    position: absolute;
    bottom: 0.3rem;
    right: 0.3rem;
    text-align: right;
    background: @color-black;
    border-radius: @border-radius-secondary;
    padding: @gap-small;
    line-height: 1;
    font-size: 1.2rem;
    color: @color-white;
    display: flex;

    .media-breakpoint-down(@screen-xs, {
      padding: @gap-small / 2;
      font-size: 1rem;
    });
  }
}
