@import "styles/mixins/_mixins.less";

.driveItemModalForm {
  width: 100%;

  .formGroup:not(:last-child) {
    margin-bottom: @gap-regular;
  }

  .titleInput {
    color: @color-gold-3;
  }
}

.label {
  display: block;
  padding-bottom: @gap-regular;
}

.disabledInput {
  background-color: @color-grey-13 !important;
}

.disabledInputContent {
  border-color: transparent !important;
}
