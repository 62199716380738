@import "styles/mixins/_mixins";

.modal {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10000;
  padding: var(--base-unit-xl) var(--base-unit-l);

  :global(.Popover) {
    z-index: 10010;
  }
}

.modalCentered {
  display: flex;
  justify-content: center;
  align-items: center;
}

.modalTopped {}

.modalRighted {}

.modalBottomed {
  display: flex;
  justify-content: center;
  align-items: flex-end;
}

.modalLefted {}

.modalBackdrop {
  background: var(--color-black);
  opacity: 0.8;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.modalInner {
  min-width: 50%;
  max-width: 80%;
  width: fit-content;
  max-height: 100%;
  z-index: 10010;
  padding-top: var(--base-unit-xl);
  border-radius: 0.6rem;
  color: var(--color-white);
  position: relative;
  overflow: hidden;
  -webkit-overflow-scrolling: touch;
  margin: 0 auto;
  background: var(--color-modal);
  display: flex;
  flex-direction: column;

  @media screen and (max-width: 991px) {
    &:not(.modalInnerFitByContent) {
      max-width: 100%;
      width: 100%;
    }
  }

  @media screen and (min-width: 1200px) {
    max-width: 92rem;

    &:not(.modalInnerFitByContent) {
      min-width: 30%;
    }
  }
}

.modalInnerStretched {
  height: 100%;
  display: flex;
  flex-direction: column;

  .modalContent {
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    overflow: auto;
  }
}

.modalInnerWide {
  width: 100%
}

.modalInnerFitByContent {
  min-width: auto;
}

.modalHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 var(--base-unit-xl);
  min-height: 3.8rem;
  flex-shrink: 0;
}

.modalTitle {
  font-family: 'IBM Plex Sans', sans-serif;
  font-size: 2.8rem;
  line-height: 1.2;
  font-weight: 500;
  margin: 0;
  padding: 0;
}

.modalControls {
  color: var(--color-white);
  position: absolute;
  top: var(--base-unit-xl);
  right: var(--base-unit-xl);
}

.modalContent {
  padding: var(--base-unit-xl);
  flex-grow: 1;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  -webkit-tap-highlight-color: transparent;
}

.modalContentWrapper {
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;

  &.modalContentWithScroll {
    padding-right: 1rem;
  }

  .has-custom-scroll(@color-black, @color-neutral);
}

.modalChildren {
  overflow: hidden;
}

.modalFooter {
  border-top: thin solid var(--color-neutral);
  padding: var(--base-unit-l) var(--base-unit-xl);
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  flex-shrink: 0;
}
