@import "styles/mixins/_mixins.less";

.richText {
  strong {
    font-weight: 600;
  }

  p {
    margin: 0;

    &:last-child {
      margin-bottom: 0;
    }
  }

  img {
    max-width: 100%;
  }

  a {
    color: var(--color-primary-links);
  }
}
