.media-in-list-placeholder {
  text-align: center;
  width: 100%;
  height: 100%;

  /** todo: hack for Waypoint pluging in Safari. There is no reaction to scroll without that */
  &__content-wrapper {
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    display: flex;

    a {
      width: 100%;
      height: 100%;
      align-items: center;
      justify-content: center;
      display: flex;
    }

    svg {
      width: 80%;
      height: 80%;
    }
  }

  &__text {
    font-size: 2.4rem;
    margin: 0 auto;
  }
}

