@import "styles/mixins/_mixins";

.metricMap {
  --metric-map-size: 18.0rem;

  text-align: center;
  width: var(--metric-map-size);
  height: var(--metric-map-size);
  background: var(--color-black);
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    max-width: var(--metric-map-size);
    max-height: var(--metric-map-size);
  }
}
