.label {
  color: var(--color-white);
  font-weight: 400;
  font-size: 1.2rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-family: var(--font-main);
  line-height: 1.2;
}

.labelXL {
  font-size: 1.6rem;
  padding: 0.1rem 1.2rem;
  height: 3.6rem;
}

.labelL {
  font-size: 1.4rem;
  padding: 0.1rem 0.8rem;
  height: 2.2rem;
}

.labelM {
  font-size: 1rem;
  padding: 0.1rem 0.4rem;
  height: 1.6rem;
}

.labelS {
  font-size: 0.8rem;
  padding: 0.1rem;
  height: 1rem;
}

.labelRound {
  border-radius: var(--border-radius-secondary);
  min-width: 1rem;
  align-items: center;
}

.labelNeutral {
  background-color: var(--color-neutral);
  border: thin solid var(--color-neutral);
}

.labelPrimary {
  background-color: var(--color-primary);
  border: thin solid var(--color-primary);
}

.labelSecondary {
  background-color: var(--color-secondary);
  color: var(--color-black);
  border: thin solid var(--color-secondary);
}

.labelTertiary {
  background-color: var(--color-tertiary);
  color: var(--color-white);
  border: thin solid var(--color-tertiary);
}

.labelQuaternary {
  background-color: var(--color-warning);
  color: var(--color-black);
  border: thin solid var(--color-warning);
}

.labelAccent {
  background-color: var(--color-accent);
  color: var(--color-white);
  border: thin solid var(--color-accent);
}

.labelWhite {
  color: var(--color-black);
  background-color: var(--color-white);
  border: thin solid var(--color-white);
}

.labelNeutralLight {
  background-color: var(--color-neutral-not-so-light);
  color: var(--color-white);
  border: thin solid var(--color-neutral-not-so-light);
}

.labelBlock {
  display: flex;
  width: 100%;
  text-align: center;
}
