.title {
  margin-bottom: var(--base-unit-l);
}

.loadingTask {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: var(--base-unit-xl) 0;
}

.loadingTask::after {
  width: 40%;
  height: 1px;
  content: "";
  background: var(--color-neutral);
  margin: var(--base-unit-xl) auto 0 auto;
}

.loadingTask:last-child::after {
  display: none;
}

.loadingTaskHeader {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.loadingTaskTitle {
  margin-right: auto;
}

.loadingTaskStatus {
  font-size: 2rem;
}

@keyframes spin { 100% { transform:rotate(-360deg); } }

.loadingTaskIconLoading {
  animation:spin 2s linear infinite;
}

.loadingTaskPercent {
  width: 100%;
  height: var(--base-unit-m);
  position: relative;
  border-radius: var(--base-unit-s);
  background: var(--color-neutral);
  margin-top: var(--base-unit-l);

  --progress: 0;
}

.loadingTaskPercent::after {
  position: absolute;
  width: var(--progress);
  height: 100%;
  content: "";
  border-radius: var(--base-unit-s);
  background: var(--color-neutral-light);
}
