.root {
  color: var(--color-white);

  > :last-child:not(:first-child) {
    margin-left: 2px;
  }

  &.block {
    display: block;
  }
}
