@import "styles/mixins/_mixins";

.patentList {
  min-width: 10.0rem;
}

.patentListItem {
  padding: @gap-regular / 2 0;
  display: flex;
}

.patentListItemCountry {
  margin-right: @gap-regular;
}

.patentListItemTitle {

}
