.menuItemSeparator {
  text-transform: uppercase;
  font-size: 1rem;
  background: var(--color-modal);
  color: var(--color-neutral-lightest);
  padding: var(--base-unit-l) var(--base-unit-xl) 0 var(--base-unit-xl);
  margin: var(--base-unit-m) 0;
}

.menuItemSeparatorLine {
  margin: var(--base-unit-m) 0;
  height: 0.1rem;
  background: var(--color-neutral);
}

.menuItemSeparatorBordered {
  border-top: thin solid var(--color-neutral);
}
