@import 'styles/mixins/_mixins';

.content {
  display: flex;
  gap: @gap-regular;
  height: 100%;
}

.rightContainer {
  display: flex;
  flex-direction: column;
  flex: 2;
  border-right: thin solid @color-neutral;
}

.inputContainer {
  margin-top: 1.5rem;
  padding: 0 var(--destination-list-horizontal-padding);
  margin-bottom: 1rem;
}

.listContainer {
  height: 100%;
  overflow: hidden;
  .is-scrollable();
}