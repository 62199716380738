@import 'styles/mixins/_mixins';

.btn {
  position: relative;
  color: inherit;
  display: inline-flex;
  font-weight: 400;
  white-space: nowrap;
  user-select: none;
  text-transform: none;
  overflow: visible;
  font-family: @font-main;
  text-decoration: none;
  cursor: pointer;
  border: thin solid transparent;
  outline: none;
  align-items: center;
  justify-content: center;
  border-radius: @border-radius-secondary;
  line-height: 1;

  .cw-icon {
    font-size: 2.4rem;
  }

  &--image {
    .btnContentWrapper {
      justify-content: center;
    }

    img {
      width: 80%;
      height: 80%;
    }
  }

  &--xl {
    padding: 0.6rem 1.8rem;
    font-size: 1.6rem;
    height: 5.4rem;
  }

  &--xl&--icon {
    font-size: 3.6rem;
    width: 5.4rem;
    padding: 0.2rem 0.4rem;

    .cw-icon {
      font-size: 3.6rem;
    }
  }

  &--xl&--image {
    width: 5.4rem;
    padding: 0;
  }

  &--m {
    padding: 0.6rem 1.8rem;
    font-size: 1.6rem;
    height: 3.6rem;
  }

  &--m&--icon {
    font-size: 2.4rem;
    width: 3.6rem;
    padding: 0.2rem 0.4rem;

    .cw-icon {
      font-size: 2.4rem;
    }
  }

  &--m&--image {
    width: 3.6rem;
    padding: 0;
  }

  &--s {
    padding: 0.4rem 2.2rem;
    font-size: 1rem;
    height: 2.4rem;

    .cw-icon {
      font-size: 1.6rem;
    }
  }

  &--s&--icon {
    width: 2.4rem;
    font-size: 1.6rem;
    padding: 0.2rem 0.4rem;

    .cw-icon {
      font-size: 1.6rem;
    }
  }

  &--s&--image {
    width: 2.4rem;
    padding: 0;
  }

  &--xs {
    padding: 0.1rem 0.4rem;
    font-size: 1.4rem;
    height: 2.0rem;

    .cw-icon {
      font-size: 1.4rem;
    }
  }

  &--xs&--icon {
    width: 2.0rem;

    .cw-icon {
      font-size: 1.4rem;
    }
  }

  &--xs&--image {
    width: 2.0rem;
  }

  // todo http://lesscss.org/functions/#list-functions-each

  &--block {
    display: flex;
    width: 100%;
  }

  &:disabled,
  &--disabled {
    opacity: .5;
    pointer-events: none;
  }

  &:not(.btn--silent):focus {
    box-shadow: 0 0 0.4rem var(--color-neutral-lightest);
    border-color: var(--color-black);
  }

  &.btn--without-paddings {
    padding: 0;
  }

  &--silent {
    cursor: default;
  }

  &--text-eillipsis {
    span {
      .has-three-dots-on-overflow;
      display: inline;
    }
  }

  a {
    color: inherit;
    text-decoration: none;
  }
}

.btnContentWrapper {
  display: inline-flex;
  align-items: center;
}

.buttonIconLeft {
  > .cw-icon {
    margin-right: 1rem;
  }
}

.buttonIconRight {
  > .cw-icon {
    margin-left: 1rem;
  }
}

.buttonDefault {
  background-color: var(--color-neutral);
  color: var(--color-white);

  &:not(.btn--silent):hover {
    background-color: @color-grey-7;
  }

  &:not(.btn--silent):active {
    background-color: #1F1F1F;
  }

  &.btn--active {
    &.btn--active-default {
      background-color: var(--color-white);
      color: var(--color-black);
    }

    &.btn--active-ambient {
      background-color: var(--color-grey-6);
      color: var(--color-white);

      &:focus {
        box-shadow: none;
        border-bottom-color: transparent;
      }
    }
  }
}

.buttonPrimary {
  background-color: var(--color-primary);
  color: var(--color-white);

  &:not(.btn--silent):hover {
    // #4A72FF;
    background-color: lighten(@color-primary, 3.2%);
  }

  &.btn--active {
    // #2342B2;
    background-color: darken(@color-primary, 4.5%);
  }
}

.buttonSecondary {
  background-color: var(--color-secondary);
  color: var(--color-black);

  &:not(.btn--silent):hover {
    background: #EBC39D;
  }

  &.btn--active {
    background-color: #9E7C5B;
  }
}

.buttonTertiary {
  background-color: var(--color-tertiary);
  color: var(--color-white);

  &:not(.btn--silent):hover {
    background: #FF6E6E;
  }

  &.btn--active {
    background-color: #BF3636;
  }
}

.buttonQuaternary {
  background-color: var(--color-warning);
  color: var(--color-black);

  &:not(.btn--silent):hover {
    background: #F5D77A;
  }

  &.btn--active {
    background-color: #EEBA1B;
  }
}

.buttonAccent {
  background-color: var(--color-accent);
  color: var(--color-white);

  &:not(.btn--silent):hover {
    background: #66D994;
  }

  &.btn--active {
    background-color: #2D8C53;
  }
}

.buttonGhost {
  background-color: transparent;
  outline: none;
  box-shadow: none;
  opacity: .8;

  &:not(.btn--silent):hover {
    opacity: 1;
  }

  &.btn--active {
    opacity: 1;
    background-color: var(--color-white);
    color: var(--color-black);
  }

  &:disabled,
  &.btn--disabled {
    opacity: .2;
  }
}

.buttonGhostWarning {
  background-color: transparent;
  outline: none;
  box-shadow: none;
  color: var(--color-tertiary);

  &:disabled,
  &.btn--disabled {
    opacity: .2;
  }
}

.buttonTransparent {
  background-color: transparent;
  outline: none;
  box-shadow: none;
  -webkit-tap-highlight-color: transparent;

  &:disabled,
  &.btn--disabled {
    opacity: .2;
  }

  &:not(.btn--silent):focus {
    box-shadow: none;
    border-color: transparent;
  }
}

.buttonTransparentHoverable {
  .buttonTransparent;

  &:hover {
    background-color: @color-grey-7;
  }
}

.buttonBordered {
  background-color: transparent;
  outline: none;
  box-shadow: none;
  -webkit-tap-highlight-color: transparent;
  border: thin solid var(--color-neutral);
  color: var(--color-black);

  &:disabled,
  &.btn--disabled {
    opacity: .2;
  }

  &.btn--active {
    background-color: var(--color-white);
  }
}

.buttonNeutralNotSoLight {
  background-color: var(--color-neutral-not-so-light);
  outline: none;
  box-shadow: none;
  -webkit-tap-highlight-color: var(--color-neutral-not-so-light);
  color: var(--color-white);

  &:disabled,
  &.btn--disabled {
    opacity: .2;
  }
}

.buttonInert {
  background-color: rgba(@color-black, 0.5);
  outline: none;
  box-shadow: none;
  color: var(--color-white);

  &:hover {
    background-color: @color-grey-7;
  }
}

.buttonTextColorDark {
  color: var(--color-black);
}

.buttonTextColorLight {
  color: var(--color-white);
}

.btnHideChildren {
  visibility: hidden;
}

.btnLoading {
  pointer-events: none;
}

.btnLoader {
  position: absolute;
}

.btnWide {
  min-width: 20rem;
}
