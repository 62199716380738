@import 'styles/mixins/_mixins';

.icon {
  line-height: 1 !important;
  color: @color-grey-22;
}

.folderIcon {
  color: @color-gold-2;
  line-height: 1 !important;
}

.urlIcon {
  line-height: 1 !important;
  color: @color-grey-22;
}

.skeleton {
  width: 100%;
  height: 100%;
  background-color: @color-bg-secondary-dark;
}

.img {
  object-fit: contain;
}
