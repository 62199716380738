@import 'styles/mixins/_mixins';

.emptyPlaceholder {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 100;
  height: 100%;
  text-align: center;
  font-size: 2.0rem;
  color: @color-secondary;
  padding: @gap-regular * 2;
}
