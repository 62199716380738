@import "styles/mixins/_mixins";

.metricScaleContainerLabel {
  flex-grow: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-right: 2px;
  white-space: nowrap;
}

.metricScaleContainerXSmall {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  flex: auto;
}

.metricScaleContainerXSmallABS {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: auto;
  line-height: 1;
  padding-top: 6px;
}

.metricScaleContainerXSmallScale {
  display: flex;
  align-items: flex-end;
  margin: 0 1px;
}

.metricScaleContainerSmall {
  min-height: 4.0rem;
}

.metricScaleContainerSmallTitle {
  font-size: 1rem;
  color: @color-white;
  height: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  overflow: hidden;
}

.metricScaleContainerSmallIcon {
  color: var(--icon-color);
  margin: 0 0.2rem;
  font-size: 1.3rem;
  display: flex;
}

.metricScaleContainerMedium {
  min-height: 2rem;
}

.metricScaleContainerMediumWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: var(--background-color);
  margin-top: @gap-small / 2;
}

.metricScaleContainerMediumTitle {
  width: 55%;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 1rem;
  color: @color-white;
  padding-right: 4px;
  overflow: hidden;
}

.metricScaleContainerMediumIcon {
  color: var(--icon-color);
  font-size: 1.2rem;
  display: flex;
  padding: 0 @gap-small;
}

.metricScaleContainerMediumScale {
  flex-basis: 45%;
}

.metricScaleContainerLarge {
  min-height: 2.6rem;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: var(--background-color);
  padding-left: @gap-small;
}

.metricScaleContainerLargeIcon {
  color: var(--icon-color);
  font-size: 1.8rem;
  display: flex;
  align-items: center;
  margin-right: @gap-small;
}

.metricScaleContainerLargeTitle {
  .metricScaleContainerLabel;

  font-size: 1.5rem;
  color: var(--label-color);
}

.metricScaleContainerLargeABS {
  font-size: 1.6rem;
  color: @color-white;
  margin-left: auto;
  margin-right: @gap-small;
}

.metricScaleContainerLargeScale {
  flex-basis: 50%;
  flex-shrink: 0;
}

.metricScaleContainerActive {
  --icon-color: @color-white;
  --label-color: @color-neutral-lightest;
  --background-color: @color-neutral-dark;
}

.metricScaleContainerInactive {
  --icon-color: @color-neutral-light;
  --label-color: @color-neutral-light;
  --background-color: none;
}
