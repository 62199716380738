@import "styles/mixins/_mixins.less";

.pinNavItem {
  display: flex;
  height: 3.45rem;
  align-items: center;
  font-weight: 450;
  font-size: 1.6rem;
  color: @color-white;
  text-align: center;

  .pinIcon {
    font-size: 1.9rem;
    line-height: 1;
  }

  .iconMore {
    font-size: 2.3rem;
    line-height: 1;
  }

  .textContainer {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: @gap-small;
    gap: @gap-small;
    color: @color-white ;
    text-decoration: none;
    background-color: @color-neutral-light;
    border-top-left-radius: 0.3rem;
    border-bottom-left-radius: 0.3rem;
    white-space: nowrap;
    overflow-wrap: normal;
    word-break: normal;
  }

  .menuItem {
    font-size: 1.6rem;
    font-weight: 450;

    .menuItemIcon {
      font-size: 2.3rem;
      line-height: 1;
      margin-right: 1rem;
    }
  }
}

.moreContainer {
  cursor: pointer;
  height: 100%;
  padding: @gap-small;
  background-color: @color-neutral-light;
  display: flex;
  justify-content: center;
  align-items: center;
  border-left: 1px solid @color-black;
  margin-right: 1px;
  border-top-right-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem;
}


.pinPopup {
  padding: 0;
}
