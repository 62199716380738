@import "styles/mixins/_mixins";

.productInfo {
  display: flex;
  justify-content: space-between;
  white-space: normal;
}

.productInfoSmall {
  flex-direction: column-reverse;
}

.productInfoDesc {
  text-align: left;
  margin-right: auto;
}

.productInfoShortView {
  line-height: 2.0rem;
  font-size: 1.6rem;
  text-decoration: none;
  display: block;
  word-break: break-word;
}

.productInfoDescRight {
  margin-left: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
