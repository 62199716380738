@import "styles/mixins/_mixins.less";

.checkboxGroupGrid {
  display: grid;
  grid-gap: var(--base-unit-l);
  justify-content: space-between;
}

.checkboxGroupGridTwoColumn {
  grid-template-columns: repeat(auto-fill, 9.9em);
}

.checkboxGroupGridThreeColumn {
  grid-template-columns: repeat(auto-fill, 5.8em);
}
