@import "styles/mixins/_mixins.less";

.buttonGroup {
  display: flex;
  flex-direction: row;

  > * {
    margin-right: var(--base-unit-m);

    &:last-child {
      margin-right: 0;
    }
  }
}
