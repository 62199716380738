@import "styles/mixins/_mixins.less";

@size: 3.6rem;

.fancyInput {
  position: relative;
  border: thin solid var(--color-neutral-not-so-light);
  border-radius: var(--border-radius-secondary);
  display: flex;
  align-items: stretch;

  &.fancyInputSuccess {
    input {
      border: thin solid var(--color-accent);

      &:hover, &:active, &:focus {
        border-color: var(--color-accent);
      }
    }
  }

  &.fancyInputError {
    border: thin solid var(--color-tertiary);

    &:hover, &:active, &:focus {
      border-color: var(--color-tertiary);
    }
  }

  &.fancyInputErrorText {
    border-color: @color-tertiary;
  }

  &.fancyInputSuccessText {
    color: var(--color-accent);
  }

  &.fancyInputHasIcon {
    .fancyInputIcon {
      flex-shrink: 0;
      width: @size;
      height: @size;
    }
  }

  &:hover, &:active, &:focus {
    border-color: var(--color-white);
  }

  input {
    width: 100%;
    height: 3.6rem;
    border: none;
    outline: none;
    color: var(--color-white);
    font-family: var(--font-main);
    font-size: 1.6rem;
    background: none;
    text-align: left;
    flex-grow: 1;
    padding: 0 var(--base-unit-l);
    -moz-appearance: textfield;

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }

  input::-webkit-input-placeholder { /* for Safari placeholder only */
    line-height: 2.4rem;
  }

  .clear {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    width: @size/2;
    height: @size;
  }
}
