.menuList {
  background: var(--color-modal);
  overflow: hidden;
  padding: calc(1.5 * var(--base-unit-m)) 0;
  font-size: 1.4rem;
}

.menuListLight {
  background-color: transparent;
}

.menuListWithRoundedCorners {
  border-radius: calc(1.5 * var(--base-unit-m));
}
