@import "styles/mixins/_mixins.less";

.forwardDestinationList {
  display: flex;
  flex-direction: column;

  .destination {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 1rem var(--destination-list-horizontal-padding);

    gap: 1.2rem;
    color: @color-grey-27;
    font-weight: 450;
    cursor: pointer;

    &:hover {
      background-color: @color-grey-7;
      border-radius: 0.3rem;
    }

    .icon {
      font-size: 4.5rem;
      line-height: 1;
    }

    .folder {
      color: @color-gold-2;
    }
  }
}

.emptyPlaceholder {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  font-size: inherit;
  color: @color-neutral-light;
  line-height: 1.4;
}

.clock {
  margin-bottom: 2rem;
  line-height: 1;
  font-size: 6.1rem;
}
