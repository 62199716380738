@import "../../../../styles/mixins/_mixins.less";

@large-height: 50.0rem;
@small-height: 30.0rem;

.collections-popover {
  width: 35.5rem;
  min-width: 35.5rem;
  padding: 0;
  background: @color-white;
  border-radius: @border-radius-secondary;

  .media-breakpoint-down(@screen-sm, {
    width: 30.0rem;
    min-width: 30.0rem;
  });

  .popover-content > button:last-child {
    border-top-right-radius: 0;
    border-top-left-radius: 0;
  }

  &__search {
    border-bottom: thin solid var(--color-border-light);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    &-icon {
      font-size: 1.8rem;
      color: @color-neutral;
      margin: 0 @gap-regular;
    }

    &-input {
      flex-grow: 1;
    }
  }

  &__collections {
    border-bottom: thin solid var(--color-border-light);
    .is-scrollable(@track-color: @color-neutral-lightest);
  }

  &__empty {
    padding: 2 * @gap-regular @gap-regular;
    color: @color-neutral;
  }

  .form-control {
    border-radius: 0;
  }

  &--full {
    .collections-popover__collections, .collections-popover__empty {
      height: @large-height;

      .media-breakpoint-down(@screen-sm, {
        height: @large-height - 20.0rem;
      });
    }
  }

  &--small {
    .collections-popover__collections, .collections-popover__empty {
      height: @small-height;

      .media-breakpoint-down(@screen-sm, {
        height: @small-height - 5.0rem;
      });
    }
  }
}

.collections-popover input {
  border: none;
  outline: none;
}
