@import 'styles/mixins/_mixins';

.heading {
  display: flex;
  gap: @gap-regular;
  align-items: center;
  margin: 1.9rem 0 2.3rem 0;

  .media-breakpoint-down(@screen-sm, {
    flex-direction: column;
    align-items: flex-start;

    :global .ql-container {
      height: 8rem;
      max-height: 8rem;
    }
  });
}
