@import "styles/mixins/_mixins";

.accessModal {
  @media screen and (min-width: @screen-sm) {
    margin-bottom: 8px;
  }

  @media screen and (min-width: @screen-sm) {
    display: flex;
    align-items: stretch;
  }

  .accessModalHeading {
    margin-bottom: 15px;

    @media screen and (min-width: @screen-sm) {
      margin-bottom: 28px;
    }
  }

  .restrictedDescription {
    padding: 12px;
  }

  .accessModalCheckbox {
    padding: 16px 13px;
    border-radius: 6px;
    width: 100%;

    @media screen and (min-width: @screen-sm) {
      min-height: 130px;
    }

    &:not(:last-of-type) {
      margin-bottom: 4px;
    }
  }

  .accessModalCheckboxActive {
    background-color: var(--color-blue-1);
  }

  .accessModalCheckboxDisabled {
    opacity: 0.3;
  }

  .accessModalCheckboxActiveDisabled {
    cursor: default;
    background-color: var(--color-grey-8);
  }

  .accessModalColumn {
    padding-top: 10px;
    margin-bottom: 15px;

    @media screen and (min-width: @screen-sm) {
      flex: 1 0 50%;
      padding-right: 25px;
      margin-bottom: 45px;
    }
  }

  .accessModalLeft {
    @media screen and (min-width: @screen-sm) {
      padding-right: 25px;
    }
  }

  .accessModalRight {
    @media screen and (min-width: @screen-sm) {
      padding-left: 25px;
    }
  }

  .accessModalOption {
    margin: 1px 0 13px;
    display: flex;
    align-items: center;
  }

  .accessModalOptionIcon {
    margin-bottom: 3px;
    color: var(--color-white);
  }

  .accessModalOptionDataIcon {
    position: relative;
    top: -6px;
    margin-bottom: 1px;
    font-size: 2.3rem;
    height: 1.8rem;
  }

  .accessModalOptionTitle {
    margin-left: 5px;
    font-weight: 450;
    line-height: 1;
  }

  .accessModalOptionDescription {
    color: var(--color-white);
    opacity: 0.6;
    font-size: 1.6rem;
    line-height: 2rem;
  }

  .accessModalSeparator {
    border-radius: 1px;
    flex: 0 0 2px;
    background-color: var(--color-grey-6)
  }
}

.recursive {
  @recursiveInputHeight: 35px;

  padding: 12px;
  margin-bottom: 18px;

  .recursiveLabel {
    margin-bottom: 11px;
    font-size: 2rem;
    line-height: 1;
    font-weight: 450;
  }

  .recursiveDescription {
    padding: 0 27px;

    @media screen and (min-width: @screen-sm) {
      display: flex;
      align-items: flex-start;
    }
  }

  .recursiveDescriptionHidden {
    visibility: hidden;
  }

  .recursiveDescriptionColumn {
    @media screen and (min-width: @screen-sm) {
      flex: 0 0 50%;

      &:first-child {
        margin-right: 10px;
      }
    }
  }

  .recursiveDescriptionLabel {
    margin-bottom: 8px;
  }

  .recursiveDescriptionInput {
    border-radius: 3px;
    background-color: var(--color-grey-8);
    padding: 6px;
    height: @recursiveInputHeight;
    display: flex;
    align-items: center;
  }

  .recursiveDescriptionIcon {
    margin-bottom: 3px;
    margin-right: 2px;
  }

  .recursiveDescriptionSecurityIcon {
    font-size: 2.5rem;
    line-height: 2.34rem;
    margin-right: 2px;
  }

  .recursiveRestricted {
    line-height: 2rem;
    height: @recursiveInputHeight;
  }
}

.accessModalFooter {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: var(--base-unit-l) 0 0 0;
}
