@import "styles/mixins/_mixins";

.loader {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;

  span {
    display: inline-block;
    border-radius: 50%;
    margin: 0 0.4rem;
    animation: bounce 1s ease-in infinite;
  }

  span:nth-child(2) {
    animation-delay: .3s;
  }

  span:last-child {
    animation-delay: .7s;
  }
}

.loaderLarge {
  padding: 2.2rem 0;

  span {
    height: 1.6rem;
    width: 1.6rem;
    margin: 0 0.4rem;
  }
}

.loaderMedium {
  padding: 1.8rem 0;

  span {
    height: 1.2rem;
    width: 1.2rem;
    margin: 0 0.4rem;
  }
}

.loaderSmall {
  padding: 1.6rem 0;

  span {
    height: 0.6rem;
    width: 0.6rem;
    margin: 0 0.2rem;
  }
}

.loaderXSmall {
  padding: 1.6rem 0;

  span {
    height: 0.3rem;
    width: 0.3rem;
    margin: 0 0.1rem;
  }
}

.loaderColored {
  span:first-child {
    background: @color-primary-dark;
  }

  span:nth-child(2) {
    background: @color-accent;
  }

  span:last-child {
    background: @color-tertiary;
  }
}

.loaderBlack {
  span {
    background-color: @color-black;
  }
}

.loaderWhite {
  span {
    background-color: @color-white;
  }
}

@keyframes bounce {
  0% {
    transform: translateY(0rem);
  }
  25% {
    transform: translateY(-0.5rem);
  }
  50% {
    transform: translateY(0rem);
  }
  75% {
    transform: translateY(0.5rem);
  }
  100% {
    transform: translateY(0rem);
  }
}
