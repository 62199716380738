@import "styles/mixins/_mixins.less";

.app {
  height: 100%;
}

.e2eMode {
  .sf-toolbar {
    display: none !important;
  }
}
