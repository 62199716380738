@import "styles/mixins/_mixins.less";

.treeItemContainer {
  color: @color-grey-18;
  display: flex;
  align-items: center;
  gap: @gap-regular;

  &.selected {
    color: @color-white;
  }
}
