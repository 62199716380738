@import "styles/mixins/_mixins.less";

.inputContainer {
  display: flex;
  align-items: center;
  gap: @gap-regular;
}

.treeItemInput {
  &.selected {
    border-color: @color-white;
  }
}
