@import "styles/mixins/_mixins.less";

.driveItemModalFooter {
  width: 100%;
  display: flex;
  gap: @gap-regular;
  justify-content: flex-end;

  &.withAddButton {
    justify-content: space-between;
  }
}


.rightGroup {
  display: flex;
  gap: 1rem;

  .button {
    width: 12rem;
  }
}

