@import 'styles/mixins/_mixins';

.action {
  color: @color-grey-9;
  display: flex;
  justify-content: flex-start;
  width: 100%;

  &:hover {
    background-color: @color-grey-7;
  }

  .media-breakpoint-down(@screen-sm, {
    background-color: @color-neutral;
  });

  :global {
    .cw-icon {
      color: @color-grey-21;
    }
  }
}
