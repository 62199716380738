@import "styles/mixins/_mixins.less";

.heading {
  color: @color-black;
  margin: 0;
  padding: 0;
  font-weight: normal;
  line-height: 1.3;

  h1& {
    font-family: @font-main;
    font-size: 3.2rem;
    font-weight: 500;
  }

  h2& {
    font-family: @font-main;
    font-size: 2.8rem;
    line-height: 1.2;
    font-weight: 500;
  }

  h3& {
    font-family: @font-main;
    font-size: 2.4rem;
    font-weight: 500;
  }

  h4& {
    font-family: @font-main;
    font-size: 2.0rem;
  }

  h5& {
    font-family: @font-main;
    font-size: 1.8rem;
  }

  h6& {
    font-family: @font-main;
    font-size: 1.8rem;
    font-weight: 500;
  }
}

.headingBright {
  color: @color-white;
}

.headingUppercase {
  text-transform: uppercase;
}

.headingBold {
  font-weight: 700;
}
