@import "styles/mixins/_mixins.less";

.socialActionContent {
  color: @color-grey-9;
  display: flex;
  align-items: center;
  justify-content: start;
  gap: 1rem;
  width: 3.6rem;
  padding: 0.2rem 0.4rem;

  &:hover {
    background-color: @color-grey-7;
  }

  .media-breakpoint-down(@screen-sm, {
    background-color: @color-neutral;
    width: 100%;
    padding: 0.6rem 1.8rem;
  });
}

.icon {
  font-size: 2.4rem;
  color: @color-grey-21;
}
