@import "../../../../styles/mixins/_mixins";

.wish-collections {
  display: flex;
  flex-direction: column;
  min-height: 10.0rem;

  &__item {
    padding: @gap-regular;
    border-bottom: thin solid var(--color-border-light);

    &:hover {
      background: darken(@color-white, 20%);
    }

    &--active {
      background: darken(@color-neutral-lightest, 10%);
    }

    &--disabled {
      opacity: 0.6;
      cursor: not-allowed;
      pointer-events: none;
    }

    &:last-child {
      border-bottom: none;
    }
  }
}

.board-item {
  position: relative;

  &__active-ctrl {
    position: absolute;
    right: -0.7rem;
    top: -0.7rem;

    &:not(.btn--active) {
      color: @color-neutral-dark !important;
    }
  }

  &__remove {
    position: absolute;
    right: -0.7rem;
    bottom: -0.7rem;
    color: @color-neutral-dark !important;
  }

  &__title {
    color: @color-primary-dark;
    font-size: 1.6rem;
    margin-right: @gap-regular / 2;
    text-align: left;
    max-width: 30.0rem;
    .has-three-dots-on-overflow();
    line-height: 1;
    height: 1.8rem;
  }

  &__desc {
    font-size: 1.2rem;
    color: @color-neutral;
    margin: @gap-regular  0;
  }

  &__date {
    color: @color-neutral;
    font-size: 1.2rem;
    font-weight: 500;
  }

  &__statuses {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    margin-top: @gap-regular;
    color: @color-neutral;
    font-size: 1.2rem;
    font-weight: 500;

    > span {
      margin-right: @gap-regular / 2;
    }

    > span:last-child {
      margin-right: 0;
    }
  }

  &__status {}
  &__products-amount {}
  &__separator {}
}
