@import "styles/mixins/_mixins";

.alert {
  color: var(--color-black);
  padding: var(--base-unit-l);
  text-align: center;
  display: block;
  width: 100%;

  > * {
    margin: 0;
  }
}

.alertWarning {
  background: var(--color-warning);
}

.alertDanger {
  background: var(--color-tertiary);
}

.alertSuccess {
  background: var(--color-accent);
}
