:root {
  --acl-user-border-color: var(--color-black);
}

.aclStatus {
  display: flex;

  > :not(:first-child) {
    margin-left: -6px;
  }

  .aclAddButton {
    &.aclAddButtonSmall {
      --size: 1.8rem;
    }

    &.aclAddButtonMedium {
      --size: 2.8rem;
    }

    &.aclAddButtonLarge {
      --size: 4rem;
    }

    width: var(--size);
    height: var(--size);
    font-size: var(--size);
    cursor: pointer;
    border: none;
    padding: 0;
    border-radius: 0;
    box-shadow: none;
    background: transparent;
    outline: none;
    color: var(--color-white);
    text-align: center;
    opacity: 0.66;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .aclAddButtonIcon {
    line-height: 1;
  }
}

.interactive {
  cursor: pointer;
}

.aclUser {
  position: relative;
    border: 2px solid var(--acl-user-border-color);
}
