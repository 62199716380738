@import 'styles/mixins/_mixins';

.wrapper {
  list-style: none;
  box-sizing: border-box;
  padding-left: calc(var(--spacing) * 1.75);
  margin-bottom: -1px;
  --vertical-padding: 10px;
  cursor: pointer;


  &.clone {
    display: inline-block;
    pointer-events: none;
    padding: 5px 0 0 10px;

    .treeItem {
      --vertical-padding: 5px;

      padding-right: 24px;
      border-radius: 4px;
      box-shadow: 0 15px 15px 0 rgba(34, 33, 81, 0.1);
    }
  }

  .selected {
    background-color: @color-grey-7 !important;
  }

  &.ghost {
    &.indicator {
      opacity: 1;
      position: relative;
      z-index: 1;
      margin-bottom: -1px;

      .treeItem {
        position: relative;
        padding: 0;
        height: 8px;
        border-color: #2389ff;
        background-color: #56a1f8;

        &:before {
          position: absolute;
          left: -8px;
          top: -4px;
          display: block;
          content: '';
          width: 12px;
          height: 12px;
          border-radius: 50%;
          border: 1px solid #2389ff;
          background-color: #ffffff;
        }

        > * {
          /* Items are hidden using height and opacity to retain focus */
          opacity: 0;
          height: 0;
        }
      }
    }

    &:not(.indicator) {
      opacity: 0.5;
    }

    .treeItem > * {
      box-shadow: none;
      background-color: transparent;
    }
  }
}

.treeItem {
  position: relative;
  display: flex;
  align-items: center;
  padding: var(--vertical-padding) 10px;
  box-sizing: border-box;

  &:hover {
    background-color: @color-grey-11;
  }
}

.count {
  position: absolute;
  top: -10px;
  right: -10px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: #2389ff;
  font-size: 0.8rem;
  font-weight: 600;
  color: #fff;
}

.disableInteraction {
  pointer-events: none;
}

.disableSelection,
.clone {
  .text,
  .count {
    user-select: none;
    -webkit-user-select: none;
  }
}

.collapse {
  svg {
    transition: transform 250ms ease;
  }

  &.collapsed svg {
    transform: rotate(-90deg);
  }

  &.hidden {
    visibility: hidden;
  }
}
