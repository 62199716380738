@import 'styles/mixins/_mixins';

.actions {
  margin-top: 1.5rem;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  .has-custom-scroll();

  .media-breakpoint-down(@screen-sm, {
    gap: 0.2rem;
    margin: 0 2.2rem 1rem 2.2rem;
  });
}

.restIcons {
  display: contents;

  .media-breakpoint-up(@screen-sm, {
    margin-top: 1.2rem;
    padding-left: 1.2rem;
    display: flex;
    gap: 1.2rem;
    flex-wrap: wrap;
  })
}


.socialAction {
  .media-breakpoint-down(@screen-sm, {
    background-color: @color-neutral;
    width: 100%;
  });
}
