@import "styles/mixins/_mixins.less";

.mediaSection {
  width: 480px;
  height: 480px;
  max-height: 100%;
  max-width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  user-select: none;

  --media-section-size: none;

  .mediaSectionFit {
    max-width: var(--media-section-size);
    max-height: var(--media-section-size);
  }
}

.mediaSectionGrayscale {
  img {
    filter: grayscale(100%);
  }
}

.mediaSectionSaturation {
  img {
    filter: saturate(200%);
  }
}
