@import "styles/mixins/_mixins";

.personalAccessModal {
  width: 100%;
  max-width: 1000px;

  .personalAccessContent {
    @media screen and (min-width: @screen-sm) {
      display: flex;
      align-items: stretch;
      margin-bottom: 22px;
      height: 530px;
      overflow: hidden;
    }
  }

  .personalAccessHeading {
    margin-bottom: 15px;

    @media screen and (min-width: @screen-sm) {
      margin-bottom: 24px;
    }
  }

  .personalAccessForm > * {
    &:not(:last-child) {
      margin-bottom: 17px;
    }
  }

  .personalAccessAdd {
    @media screen and (min-width: @screen-sm) {
      margin-top: 12px;
    }
  }

  .recursiveCheckbox {
    margin-bottom: 10px;
  }

  .recursiveCheckboxLabel {
    width: 100%;
    margin-right: 2.5rem;
  }

  .recursiveLabel {
    font-size: 2rem;
    line-height: 1;
    font-weight: 450;
  }

  .recursiveDescriptionInput {
    background-color: @color-neutral;
    box-shadow: none;
    border-color: @color-neutral;
    border-radius: 0.3rem;
    padding: 6px;
    height: 36px;
    display: flex;
    align-items: center;

    &.recursiveDescriptionInputHidden {
      visibility: hidden;
    }
  }

  .recursiveDescriptionIcon {
    margin-bottom: 3px;
    margin-right: 2px;
  }

  .recursiveDescriptionSecurityIcon {
    font-size: 1.7rem;
    margin-right: 3px;
    line-height: 1;
  }

  .personalAccessLeft {
    padding: 10px 12px;
    border-radius: 12px;
    border: 1px solid var(--color-border-color);
    background: var(--color-black);

    @media screen and (min-width: @screen-sm) {
      flex: 0 0 48%;
      padding: 22px 25px;
      height: 100%;

      .is-scrollable();
    }
  }

  .personalAccessRight {
    padding: 10px 12px;
    overflow: hidden;
    display: flex;
    flex-direction: column;

    @media screen and (min-width: @screen-sm) {
      flex: 0 0 52%;
      padding: 22px 0 22px 25px;
      height: 100%;
    }

    .personalAccessHeading {
      flex-shrink: 0;
    }

    .personalAccessRightContent {
      flex-grow: 1;
      overflow: auto;
      min-height: 50px;
      padding: 20px 0;

      .is-scrollable();

      .personalAccessRightEmpty {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        color: var(--color-white);
        opacity: 0.6;
      }
    }

    .personalAccessRightLoader {
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .personalAccessFooter {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: var(--base-unit-l) 0 0 0;
  }

  .userOption {
    display: flex;
    align-items: center;
  }

  .userOptionDescription {
    margin-left: 12px;
  }

  .userOptionName {
    font-weight: 450;
  }

  .userOptionCompany {
    opacity: 0.66;
  }

  .userSelectedOption {
    display: flex;
    align-items: center;
    padding: 4px 0;
  }

  .userSelectedOptionName {
    display: inline-block;
    margin-left: 3px;
  }
}
