@import "styles/mixins/_mixins.less";

.darkTheme {
  :global {
    .ql-snow {
      border: thin solid @color-neutral-not-so-light;
    }

    .ql-container {
      height: 10rem;
      max-height: 10rem;
      overflow: auto;
      border-radius: var(--border-radius-secondary);
    }

    .ql-stroke {
      stroke: @color-white;
    }

    .ql-fill {
      fill: @color-white;
    }

    .ql-editor {
      font-size: 1.6rem;
    }

    // placeholder styling
    .ql-editor.ql-blank::before {
      color: @color-neutral-light;
    }
  }
}
