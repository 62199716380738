@import "styles/mixins/_mixins.less";

:root {
  --sidebar-width: 30.0rem;
}

.layout {
  display: grid;
  grid-template-rows: minmax(100%, max-content);
  padding-top: @header-height;
  width: 100%;
}

// this CSS class comes from Cutwise Player
// iOS Safari doesn't hide layour outside of fullscreen div element
:global(.cwp-has-fullscreen-element) .layout {
  padding-top: 0;
  height: 100%;
}

.layoutStrictHeight {
  height: 100%;
  position: fixed;
  overflow: hidden;
}

.layoutTwoColumn {
  grid-template-areas: "sidebar content";
  grid-template-rows: 100%;

  .media-breakpoint-down(@screen-md, {
    grid-template-areas: "content" !important;
    position: static;
    width: 100%;
  });
}

.layoutWithoutSidebar {
  grid-template-areas: "content" !important;
  grid-template-columns: 100%;
}

.layoutWithSidebar {
  grid-template-columns: var(--sidebar-width) calc(100% - var(--sidebar-width));

  .media-breakpoint-down(@screen-md, {
    grid-template-columns: 100%;
  });

  .media-breakpoint-down(@screen-sm, {
    grid-template-rows: auto;
  });
}

.layoutOneColumn {
  grid-template-areas: "content";
  grid-template-columns: 100%;
}
