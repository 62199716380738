@import "styles/mixins/_mixins";

.expensesProgress {
  /* from design */
  width: 52px;
  margin-right: 12px;
  flex-shrink: 0;
}

.expensesExpandLink {
  .reset-button();

  margin-left: auto;
  color: var(--color-primary-links);
  cursor: pointer;
  border-bottom: 1px dashed var(--color-primary-links);

}
