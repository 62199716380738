@import "styles/mixins/_mixins.less";

.btnGroup {
  display: flex;
  flex-direction: row;

  > button, input {
    &:not(:first-of-type):not(:last-of-type) {
      border-radius: 0;
      border-left: thin solid @color-bg-secondary-dark;
      border-right: none;
    }

    &:first-of-type:not(:last-of-type) {
      border-radius: @border-radius-secondary 0 0 @border-radius-secondary;
      border-left: none;
    }

    &:last-of-type:not(:first-of-type) {
      border-radius: 0 @border-radius-secondary @border-radius-secondary 0;
      border-left: thin solid @color-bg-secondary-dark;
    }
  }
}

.media-breakpoint-down(@screen-md, {
  .btnGroup.btnGroupIsHorizontal {
    > button, input {
      &:first-child {
        border-radius: @border-radius-secondary 0 0 @border-radius-secondary !important;
      }

      &:last-child {
        border-radius: 0 @border-radius-secondary @border-radius-secondary 0 !important;
      }
    }
  }
});

.btnGroupBlock {
  width: 100%;
}

.btnGroupWithOutline {
  padding: 0.2rem;
  background: var(--color-neutral);
  border-radius: @border-radius-secondary;
}
