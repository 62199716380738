@import "styles/mixins/_mixins.less";

.checkbox, .radio {
  margin-top: 0.7rem;
  margin-bottom: 0.7rem;
}

.btn[disabled] {
  pointer-events: none;
}

// todo: remove old bootstrap styles
.clearfix {
  .clearfix();
}

.pull-left {
  float: left;
}

.pull-right {
  float: right;
}

.form-control {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding: 0.6rem 1rem;
  font-size: 1.6rem;
  line-height: 1.3;
  color: #555;
  background-color: #fff;
  background-image: none;
  border: thin solid #ccc;
  border-radius: 0.4rem;
  -webkit-box-shadow: inset 0 0.1rem 0.1rem rgba(0, 0, 0, .075);
  box-shadow: inset 0 0.1rem 0.1rem rgba(0, 0, 0, .075);
  transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
  font-family: @font-main;
}

.input-group {
  position: relative;
  display: table;
  border-collapse: separate;
}

.input-group .form-control, .input-group-addon {
  display: table-cell;
}

.input-group .form-control {
  position: relative;
  z-index: 2;
  float: left;
  width: 100%;
  margin-bottom: 0;
}

.btn-group .form-control:first-child {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.Popover {
  z-index: 100;
  background: var(--color-modal);
  display: block !important;
  transition-property: none !important;

  border-radius: 1.2rem;
  box-shadow: none;
  padding: 0;
  border: thin solid var(--color-neutral);
  overflow: hidden;

  &--colored {
    background: @color-secondary;
    color: @color-black;
  }

  &--with-padding {
    padding: @gap-regular;
  }

  &-tip {
    display: none !important;
  }
}

// https://github.com/fkhadra/react-toastify

.Toastify__toast-container {
  z-index: 99999;
  -webkit-transform: translate3d(0, 0, 9999px);
  position: fixed;
  padding: 0.4rem;
  width: 320px;
  box-sizing: border-box;
  color: #fff;
}
.Toastify__toast-container--top-center {
  top: 1em;
  left: 50%;
  transform: translateX(-50%);
}
.Toastify__toast-container--top-right {
  top: 1em;
  right: 1em;
}

@media only screen and (max-width : 480px) {
  .Toastify__toast-container {
    width: 100vw;
    padding: 0;
    left: 0;
    margin: 0;
  }
  .Toastify__toast-container--top-center, .Toastify__toast-container--top-right {
    top: 0;
    transform: translateX(0);
  }
}

.Toastify__toast {
  position: relative;
  min-height: 6.4rem;
  box-sizing: border-box;
  margin-bottom: 1.6rem;
  padding: 0.8rem;
  border-radius: 0.4rem;
  box-shadow: 0 0.1rem 1rem 0 rgba(0, 0, 0, 0.1), 0 0.2rem 1.5rem 0 rgba(0, 0, 0, 0.05);
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
  justify-content: space-between;
  max-height: 800px;
  overflow: hidden;
  font-family: sans-serif;
  cursor: pointer;
  direction: ltr;
}

.Toastify__toast--default {
  background: #fff;
  color: #aaa;
}

.Toastify__toast--info {
  background: var(--color-regular-links);
}

.Toastify__toast--success {
  background: var(--color-accent);
}

.Toastify__toast--warning {
  background: var(--color-warning);
}

.Toastify__toast--error {
  background: var(--color-tertiary);
}

.Toastify__toast-body {
  margin: auto 0;
  padding: 0.6rem;
}

.Toastify--animate {
  animation-fill-mode: both;
  animation-duration: 0.2s;
}

@media only screen and (max-width : 480px) {
  .Toastify__toast {
    margin-bottom: 0;
  }
}

.Toastify__close-button {
  color: #fff;
  background: transparent;
  outline: none;
  border: none;
  padding: 0;
  cursor: pointer;
  opacity: 0.7;
  transition: 0.2s ease;
  align-self: flex-start;
}

.Toastify__close-button--default {
  color: #000;
  opacity: 0.3;
}

.Toastify__close-button > svg {
  fill: currentColor;
  height: 1.6rem;
  width: 1.4rem;
}

.Toastify__close-button:hover, .Toastify__close-button:focus {
  opacity: 1;
}

@keyframes Toastify__trackProgress {
  0% {
    transform: scaleX(1);
  }
  100% {
    transform: scaleX(0);
  }
}

.Toastify__progress-bar {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 0.5rem;
  z-index: 9999;
  opacity: 0.7;
  background-color: rgba(255, 255, 255, 0.7);
  transform-origin: left;
}

.Toastify__progress-bar--animated {
  animation: Toastify__trackProgress linear 1 forwards;
}

.Toastify__progress-bar--controlled {
  transition: transform 0.2s;
}

.Toastify__progress-bar--default {
  background: linear-gradient(to right, #4cd964, #5ac8fa, #007aff, #34aadc, #5856d6, #ff2d55);
}

@keyframes Toastify__zoomIn {
  from {
    opacity: 0;
    transform: scale3d(0.3, 0.3, 0.3);
  }
  50% {
    opacity: 1;
  }
}

@keyframes Toastify__zoomOut {
  from {
    opacity: 1;
  }
  50% {
    opacity: 0;
    transform: scale3d(0.3, 0.3, 0.3);
  }
  to {
    opacity: 0;
  }
}

.Toastify__zoom-enter {
  animation-name: Toastify__zoomIn;
}

.Toastify__zoom-exit {
  animation-name: Toastify__zoomOut;
}

// https://github.com/zenoamaro/react-quill
.ql-container {
  strong {
    font-weight: 600;
  }
}
