@import 'styles/mixins/_mixins';

.link {
  text-decoration: none;
  color: var(--color-regular-links);
  text-underline-position: under;
}

@media (hover: hover) {
  .link:hover {
    text-decoration: underline;
  }
}

.link:visited {
  color: var(--color-regular-links-visited);
}

.nav {
  color: var(--color-font-main-light);
  text-decoration: none;
  text-underline-position: under;
}

@media (hover: hover) {
  .nav:hover {
    text-decoration: underline;
  }
}

.nav:visited {
  color: var(--color-font-main-light);
}
