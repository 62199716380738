@import "styles/mixins/_mixins.less";

.gem-outline {
  polygon, path {
    fill: none;
    stroke: #878787;
    stroke-width: 0.4;
    stroke-opacity: 1;
    stroke-linecap: round;
    stroke-miterlimit: 1;
  }
  *[class^='cls-'] {
    opacity: 2 !important;
  }
}

.gem-outline-brown {
  polygon, path {
    fill: none;
    stroke: var(--color-secondary);
    stroke-width: 0.4;
    stroke-opacity: 1;
    stroke-linecap: round;
    stroke-miterlimit: 1;
  }
  *[class^='cls-'] {
    opacity: 2 !important;
  }
}

.gem-outline-green {
  polygon, path {
    fill: none;
    stroke: var(--color-accent);
    stroke-width: 0.4;
    stroke-opacity: 1;
    stroke-linecap: round;
    stroke-miterlimit: 1;
  }
  *[class^='cls-'] {
    opacity: 2 !important;
  }
}

.gem {
  height: 100%;

  .cls-1 {
    opacity: 0.1;
  }
  .cls-2 {
    opacity: 0.2;
  }
  .cls-3 {
    opacity: 0.3;
  }
  .cls-4 {
    opacity: 0.4;
  }
  .cls-5 {
    opacity: 0.5;
  }
  .cls-6 {
    opacity: 0.6;
  }
  .cls-7 {
    opacity: 0.7;
  }
  .cls-8 {
    opacity: 0.8;
  }
  .cls-9 {
    opacity: 0.9;
  }
  .cls-10 {
    opacity: 1;
  }

  &--bold {
    polygon, path {
      stroke-width: 1;
    }

    .cls-1 {
      opacity: 0.55;
    }
    .cls-2 {
      opacity: 0.6;
    }
    .cls-3 {
      opacity: 0.65;
    }
    .cls-4 {
      opacity: 0.7;
    }
    .cls-5 {
      opacity: 0.75;
    }
    .cls-6 {
      opacity: 0.8;
    }
    .cls-7 {
      opacity: 0.85;
    }
    .cls-8 {
      opacity: 0.9;
    }
    .cls-9 {
      opacity: 0.95;
    }
    .cls-10 {
      opacity: 1;
    }
  }
}
