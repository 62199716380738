.documentationLink {
  display: inline-flex;
  align-items: center;

  &:hover {
    text-decoration: none;

    & span:not(.documentationLinkIcon) {
      text-decoration: underline;
    }
  }
}

.documentationLinkIcon {
  color: var(--color-white);
  margin: 0 var(--base-unit-m) 0 0;
}
