@import "styles/mixins/_mixins.less";

.formLabel {
  font-size: 1.6rem;
  font-weight: 500;
  color: @color-white;
  padding: 0 0 @gap-regular 0;
  display: flex;
  align-items: center;
}

.formLabelActiveFilter {
  color: @color-white;
}

.formLabelNonActiveFilter {
  color: @color-neutral-lightest;
}
