@import "styles/mixins/_mixins";

.productPrice {
  font-size: 2.0rem;
  flex-shrink: 0;

  @media @smartphone {
    font-size: 1.4rem;
  }
}

.productPriceABS {
  color: @color-secondary;
  white-space: nowrap;
}

.productPriceCt {
  color: @color-white;
  font-size: 1.6rem;
  white-space: nowrap;
}

.productPriceRightAligned {
  text-align: right;
}

.productPriceLeftAligned {
  text-align: left;
}
