@import "styles/mixins/_mixins";

.personalAccessUser {
  padding-bottom: 25px;

  &:hover .personalAccessMore {
    background: var(--color-modal);
  }

  .personalAccessUserContent {
    display: flex;
    align-items: center;
    cursor: pointer;
    width: 100%;
    padding: 15px 12px;
  }

  .personalAccessAvatar {
    margin: 1px 10px 0 0;
  }

  .personalAccessDescription {
    margin-right: auto;
    padding-right: 12px;
    padding-top: 2px;
  }

  .personalAccessDescriptionName {
    & span {
      display: inline-block;
    }
  }

  .personalAccessDescriptionAccess {
    display: flex;
    align-items: center;
  }

  .personalAccessDescriptionIcon {
    font-size: 1.7rem;
    margin-left: -4px;
    margin-right: 3px;
    line-height: 1;
  }

  .personalAccessDescriptionName {
    color: var(--color-white);
    opacity: .6;
  }
}

.personalAccessOptionRemove {
  position: relative;
  margin-top: 9px;
  padding: 0.85rem 1.1rem 0;

  &:after {
    content: '';
    position: absolute;
    height: 1px;
    background-color: @color-grey-6;
    top: -5px;
    left: 0;
    width: 100%;
  }
}
