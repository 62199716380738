@import "styles/mixins/_mixins";

.productLabels {
  font-size: 1.6rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  width: max-content;

  & > * {
    margin-left: var(--base-unit-m);
    margin-bottom: 0.2rem;

    &:first-child {
      margin-left: 0;
    }
  }
}

.productLabelsCentered {
  justify-content: center;
}
