@import "styles/mixins/_mixins.less";

.collectionListItem {
  cursor: pointer;
  padding: 1.5rem;

  &:hover {
    background-color: @color-grey-11;
  }

  &.selected {
    background-color: @color-grey-7;
  }


  &.disabled {
    background-color: @color-grey-7;
    opacity: 0.8;
    cursor: inherit;
  }

  .icon {
    font-size: 2.4rem;
    line-height: 1;
  }

}
