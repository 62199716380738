@import "styles/mixins/_mixins";

.content {
  z-index: 100;
  background: @color-grey-25;
  display: block;
  transition-property: none;
  box-shadow: none;
  outline: none;
  overflow: hidden;
  font-size: 1.3rem;
  line-height: 2.1rem;
  border-radius: 1.2rem;
  border: thin solid @color-neutral;
  padding: 1.2rem 1.7rem;
}
