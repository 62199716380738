@import 'styles/mixins/_mixins';

.tabsContainer {
  width: 100%;
  padding: 0 2.2rem;
  margin-bottom: 1.5rem;

  .clockIcon {
    font-size: 2.1rem !important;
  }

  .moreIcon {
    font-size: 1.9rem !important;
  }
}

.title {
  margin-bottom: 1.2rem;
}

.tabs {
  display: flex;
  width: 100%;
}

.tab {
  flex: 1;
}
