/* Text utils */
@align: left, center, right, justify;
each(@align, {
  .has-text-@{value} {
    text-align: @value;
  }
});

/* Display utils */
@display: none, block, inline, flex, inline-flex;
each(@display, {
  .d-@{value} {
    display: @value;
  }
});

.ov-hidden {
  overflow: hidden;
}

.ml-auto {
  margin-left: auto;
}

.mr-auto {
  margin-right: auto;
}

/* Flex utils */
@direction: row, row-reverse, column, column-reverse;
each(@direction, {
  .flex-@{value} {
    flex-direction: @value;
  }
});

@alignItems: center, flex-start, flex-end, stretch;
each(@alignItems, {
  .flex-align-@{value} {
    align-items: @value;
  }
});

@justifyContent: center, flex-start, flex-end, stretch, space-between;
each(@justifyContent, {
  .flex-justify-@{value} {
    justify-content: @value;
  }
});

.flex-shrink-0 {
  flex-shrink: 0;
}

.flex-grow-1 {
  flex-grow: 1;
}

/* Dimension utils */
.is-full-width {
  width: 100%;
}

.is-full-height {
  height: 100%;
}
