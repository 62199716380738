@import "styles/mixins/_mixins.less";

.fancy-select {
  min-width: 16.0rem;

  @media @smartphone {
    min-width: 10.0rem;
  }

  &--without-border {
    .fancy-select__control {
      border: thin solid transparent;
    }
  }

  &--no-arrow .fancy-select__dropdown-indicator {
    display: none;
  }

  .fancy-select__menu {
    z-index: 10 !important;
  }

  .fancy-select__control {
    min-height: 3.6rem !important;
    height: auto;
    font-size: 1.6rem;
    border-radius: 0.2rem;
  }

  .fancy-select__indicator-separator {
    display: none;
  }

  .fancy-select--without-border .fancy-select__control {
    border-collapse: inherit;
    border-radius: 0;
    border: none !important;
    box-shadow: none !important;
  }

  &:hover {
    > .fancy-select__control {
      background: @color-grey-7;
      border-color: transparent;
    }
  }

  &:focus {
    outline: none;
    box-shadow: 0 0 0.4rem @color-neutral-lightest !important;

    > .fancy-select__control {
      border-color: @color-black;
    }
  }

  &:active {
    > .fancy-select__control {
      background: #262626;
      border-color: #262626;
    }
  }

  .fancy-select__control {
    background-color: @color-neutral;
    box-shadow: none;
    border-color: @color-neutral;
    border-radius: @border-radius-secondary;

    &--is-disabled {
      background-color: @color-neutral-dark !important;
      opacity: 0.5;
    }
  }

  .fancy-select__single-value, input {
    color: @color-white !important;
  }

  .fancy-select__option, .fancy-select__menu {
    background: transparent;
    color: @color-black;
  }

  .fancy-select__option {
    cursor: pointer;
    padding: 0.85rem 1.1rem 1.3rem;
    color: @color-black;
    border-bottom: none;

    &:hover {
      color: @color-black;
      background-color: @color-grey-9;
    }
  }

  .fancy-select__menu-list {
    .has-custom-scroll(@track-color: 'white');

    z-index: 10;
    border-color: transparent;
    font-size: 1.6rem;
    border-radius: @border-radius-secondary;
    background: white;
    top: 125%;
    padding: 4px 0;
  }

  .fancy-select__option--is-selected {
    background: var(--color-neutral-lightest);
  }

  .fancy-select__single-value {
    width: 90%;
  }

  .fancy-select__multi-value {
    border-radius: 12px;
    padding: 0 0 0 6px;
    background-color: @color-white;
    color: @color-black;
  }

  .fancy-select__multi-value__remove {
    border-radius: 100%;
    cursor: pointer;
    width: 18px;
    padding: 0;
    margin: 0 4px 0 3px;

    &:hover {
      background: transparent;
      color: @color-black;
    }
  }

  .fancy-select__group-heading {
    font-size: 1.6rem;
    font-weight: 450;
    opacity: 0.33;
    text-transform: none;
  }

  .fancy-select__option-title {
    display: flex;
    align-items: center;
    line-height: normal;
  }

  .fancy-select__option-icon {
    display: flex;
    align-items: center;
    font-size: 1.7rem;
    margin-right: 3px;
    line-height: 1;
  }

  .fancy-select__option-label {
    font-size: 1.6rem;
    font-weight: 450;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  .fancy-select__option-description {
    font-size: 1.47rem;
    font-weight: 450;
    opacity: 0.4;
    padding-top: 0.75rem;
  }
}
