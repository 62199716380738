.linearProgress {
  height: 16px;
  background-color: transparent;
}

.linearProgressValue {
  height: 100%;
}

.purpleLinearProgress {
  .linearProgressValue {
    background-color: var(--color-purple);
  }
}

.orangeLinearProgress {
  .linearProgressValue {
    background-color: var(--color-secondary);
  }
}

.greyLinearProgress {
  .linearProgressValue {
    background-color: var(--color-grey-4);
  }
}
